<template>
  <Icon
    v-if="checked"
    key="MdiCheckboxMarkedCircle"
    name="MdiCheckboxMarkedCircle"
    class="w-6 h-6"
    :class="`text-${checkedTheme}-500`"
  />
  <Icon
    v-else
    key="MdiCheckboxBlankCircleOutline"
    name="MdiCheckboxBlankCircleOutline"
    class="text-gray-400 w-6 h-6"
  />
</template>

<script>
export default {
  props: {
    checked: Boolean,
    checkedTheme: {
      type: String,
      default: "success",
    },
  },
};
</script>
